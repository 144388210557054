<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
    data() {
        return {
            title: "Penandatangan",
            user_id: this.$route.params.user_id,
            penandatangan: [],
            configuration: [],
        };
    },
    mounted() {
        this.getUserPenandatangan();
        this.getKonfig();
    },
    methods: {
        getUserPenandatangan() {
            let self = this;
            Swal.fire({
                title: '<i class="fas fa-spinner fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
            });
            axios
                .get(process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-user-penandatangan", {
                    params: {
                        user_id: self.user_id,
                    },
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer " + localStorage.access_token,
                    },
                })
                .then(function (response) {
                    var res_fix = response.data.data;
                    self.penandatangan = res_fix;
                    self.iframe_pdf_surat_tugas_fix = '<iframe src="' + res_fix.pdf + '" width="100%" height="400"></iframe>';
                    console.log(self.penandatangan);
                    Swal.close();
                })
                .catch((e) => {
                    this.axiosCatchError = e.response;
                    Swal.close();
                });
        },
        getKonfig() {
            let self = this;
            var config_login = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/konfig-login",
                headers: {
                    Accept: "application/json",
                },
            };
            axios(config_login)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.configuration = response_data_fix.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    },
};
</script>
<template>
    <div class="account-pages my-5 pt-5">
        <div
            class="bg-overlay"
            v-bind:style="{
                background: 'url(' + configuration.LOGIN_BACKGROUND + ')',
                backgroundSize: 'cover',
                backgroundRepeat: 'no repeat',
                backgroundPosition: 'center',
            }"></div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="card-header"><h3>Penandatangan</h3></div>
                        <div class="card-body">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td style="width: 350px"><b>Nomor Induk Kepegawaian</b></td>
                                        <td style="width: 15px">:</td>
                                        <td>{{ penandatangan.nik }}</td>
                                    </tr>
                                    <tr>
                                        <td style="width: 350px"><b>Nama</b></td>
                                        <td style="width: 15px">:</td>
                                        <td>{{ penandatangan.name }}</td>
                                    </tr>
                                    <tr>
                                        <td style="width: 350px"><b>Jabatan</b></td>
                                        <td style="width: 15px">:</td>
                                        <td>{{ penandatangan.jabatan }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
